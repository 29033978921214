<template>
  <div class="patE">
    <div class="banner">
      <div class="banner-box">
        <div class="banner-text">
          <p class="title">OpenAPI</p>
          <p class="sub-title">
            中知数通拥有国家知识产权局全面、权威、及时的知识产权数据资源和科创产业生态数据，知识产权数据规模<br/>
            超5亿条，融汇超1000万家企业的商情、舆情数据等多维数据。凭借知识产权专家、技术专家成熟的数据解析<br/>
            与深度加工能力，中知数通沉淀了标准化的API数据应用服务，可满足金融机构及其他行业客户的调用需求。<br/>
            <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;API开发使用SpringCloud微服务框架搭建系统，支持大数据量、高并发的数据操作，平台无关性，每个微<br/>
            服务应用可独立部署，业务隔离、逻辑清晰、可维护性高。
          </p>
        </div>
        <img class="banner-img" src="https://images.zzt.com.cn/ks/2023/04/17/mark.png" width="294">
      </div>
    </div>
    <section>
      <div class="tec">
        <div class="tec-wrap">
          <div class="tec-content">
            <p class="title">基础数据</p>
            <ul class="desc">
              <li>专利数据API</li>
              <li>标准数据API</li>
              <li>版权数据API</li>
              <li>地理标志数据API</li>
              <li>知识产权判例数据API</li>
              <li>商标数据API</li>
            </ul>
            <p class="Button cursor-pointer" @click="experience">联系客服</p>
          </div>
          <img class="tec-img" src="https://images.zzt.com.cn/ox/2022/12/12/tec.png" width="810">
        </div>
      </div>
      <div class="track">
        <div class="track-wrap">
          <img class="track-img" src="https://images.zzt.com.cn/ox/2022/12/12/track.png" width="810">
          <div class="track-content">
            <p class="title">精加工数据</p>
            <ul class="desc">
              <li>企业专利数据API</li>
              <li>高价值专利API</li>
              <li>专利价值评估API</li>
              <li>企业科技力评价API</li>
            </ul>
            <p class="Button cursor-pointer" @click="experience">联系客服</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

export default {
  methods: {
    experience () {
      window.open('https://pate.zzt.com.cn/login')
    }
  }
}
</script>
<style lang='scss' scoped>
.Button {
  width: Translate(140px);
  height: Translate(36px);
  border: Translate(1px) solid #fff;
  font-size: Translate(14px);
  color: #FFFFFF;
  text-align: center;
  line-height: Translate(36px);
  border-radius: Translate(4px);
  background-color: #4088EB;
  &:hover {
    background: #4299eb;
    color: #fff;
    border: Translate(1px) solid #fff;
  }
}
.patE {
  p {
    margin: 0;
  }
  .banner {
    width: 100%;
    min-width: $container-width;
    height: Translate(500px);
    background: url("https://images.zzt.com.cn/ks/2023/04/17/product-banner-background.png") no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0 auto;
    // background-image: linear-gradient(-83deg, #68BAFF 0%, #4088EB 100%);
    &-box {
      width: $container-width;
      margin: 0 auto;
      position: relative;
      height: 100%;
    }
    &-text {
      text-align: left;
      color: #fff;
      .title {
        position: absolute;
        top: Translate(110px);
        left: 0;
        color:#333;
        font-size: Translate(40px);
        font-weight: 500;
      }
      .sub-title {
        position: absolute;
        top: Translate(180px);
        left: 0;
        color:#666;
        font-size: Translate(16px);
        line-height: Translate(34px);
      }
      .btn-container{
        width: Translate(516px);
        position: absolute;
        top: Translate(340px);
        left: 0;
        display: flex;
        justify-content: space-between;
        .btn {
          width: Translate(160px);
          height: Translate(40px);
          border: Translate(1px) solid #4088EB;
          font-size: Translate(14px);
          color: #4088EB;
          text-align: center;
          background: #fff;
          line-height: Translate(40px);
          border-radius: Translate(4px);
          &:hover {
            background: #4088EB;
            color: #fff;
            border: 1px solid #4088EB;
        }
      }
     }
    }
    &-img {
      position: absolute;
      right: Translate(55px);
      top: Translate(89px);
    }
  }
  section {
    background-color: #fff;
    img {
      font-size: 0;
    }
    .tec, .track, .mark {
      display: flex;
      align-items: center;
      justify-content: center;
      height: Translate(654px);
      min-width: $container-width;
      max-width: Translate(1920px);
      margin: 0 auto;
      &-wrap {
        display: flex;
        width: $container-width;
        justify-content: space-between;
        align-items: center;
      }
      &-content {
        width: Translate(240px);
        text-align: left;
        .title {
          font-size: Translate(32px);
          font-weight: 500;
          color:#333;
          margin-bottom: Translate(20px);
        }
        .desc {
          padding: 0;
          width: Translate(335px);
          li::before{
            position: relative;
            left: Translate(-15px);
            top: Translate(20px);
            content: '';
            display: block;
            width: Translate(6px);
            height: Translate(6px);
            border-radius: 50%;
            background: #999;
          }
          li:nth-child(2n){
            margin-left: Translate(46px);
          }
          li:nth-child(2),
          li:nth-child(4){
            margin-left: Translate(110px);
          }
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          font-size: Translate(16px);
          color:#666;
          line-height: Translate(34px);
          margin-bottom: Translate(40px);
        }
      }
    }
    .tec {
      background: url("https://images.zzt.com.cn/ox/2022/12/12/tec-bg.png") no-repeat;
      width: 100%;
      background-position: center center;
      background-size: cover;
    }
    .track {
      background: url("https://images.zzt.com.cn/ox/2022/12/23/track-bg.png") no-repeat;
      width: 100%;
      background-position: center center;
      background-size: cover;
      max-width: unset;
      .desc{
          padding: 0;
          width: Translate(335px);
          li::before{
            position: relative;
            left: Translate(-15px);
            top: Translate(20px);
            content: '';
            display: block;
            width: Translate(6px);
            height: Translate(6px);
            border-radius: 50%;
            background: #999;
          }
          li:nth-child(2n){
            margin-left: Translate(46px);
          }
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          font-size: Translate(16px);
          color:#666;
          line-height: Translate(34px);
          margin-bottom: Translate(40px);
      }
    }
  }
}
</style>
